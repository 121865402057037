import _ from "lodash";
import { GalleryAsset } from "@/models/asset";

export default {
  cacheAsset(
    state: any,
    payload: {
      items: Array<GalleryAsset>;
      total?: number;
      type: "videos" | "uploads" | "slides";
    }
  ) {
    const urls = _.map(state.assets[payload.type].list, (item) => item.url);
    const ids = _.map(state.assets[payload.type].list, (item) => item.id);
    if (state.assets.defaults.image && state.assets.defaults.video) {
      _.forEach(payload.items, (item) => {
        const isImageExcluded =
          urls.includes(item.url) ||
          item.url === state.assets.defaults.image.url;
        const isVideoExcluded =
          urls.includes(item.url) ||
          item.id === state.assets.defaults.video.id ||
          item.name.includes("HowToUse-1.0") ||
          item.id.toString().includes("59ea2a1e-b81a-4f3f-9ae1-789296833d95");

        if (!(isImageExcluded || isVideoExcluded)) {
          state.assets[payload.type].list.push(item);
        }
      });
    } else if (state.assets.defaults.image) {
      _.forEach(payload.items, (item) => {
        if (
          !(
            urls.includes(item.url) ||
            item.url === state.assets.defaults.image.url
          )
        ) {
          state.assets[payload.type].list.push(item);
        }
      });
    } else if (state.assets.defaults.video) {
      _.forEach(payload.items, (item) => {
        if (
          !(
            urls.includes(item.url) ||
            item.id === state.assets.defaults.video.id ||
            item.name.includes("HowToUse-1.0") ||
            item.id.toString().includes("59ea2a1e-b81a-4f3f-9ae1-789296833d95")
          )
        ) {
          state.assets[payload.type].list.push(item);
        }
      });
    } else {
      _.forEach(payload.items, (item) => {
        if (!urls.includes(item.url) && !ids.includes(item.id)) {
          state.assets[payload.type].list.push(item);
        }
      });
    }
    if (payload.total != undefined) {
      state.assets[payload.type].total = payload.total;
    }
  },
  updateAsset(
    state: any,
    payload: { type: string; id: string; newValue: GalleryAsset }
  ) {
    const assetToUpdate = _.find(
      state.assets[payload.type].list,
      (asset) => asset.id === payload.id
    );
    if (assetToUpdate) {
      Object.assign(assetToUpdate, payload.newValue);
    }
  },
  setStatus(state: any, payload: { type: string; id: string; value: boolean }) {
    const assetToUpdate = _.find(
      state.assets[payload.type].list,
      (asset) => asset.id === payload.id
    );
    if (assetToUpdate) {
      assetToUpdate.status = payload.value;
    }
  },
  setFullyLoaded(state: any, payload: { type: string; value: boolean }) {
    state.assets[payload.type].fullyLoaded = payload.value;
  },
  setBlobContToken(state: any, payload: { type: string; value: string }) {
    state.assets[payload.type].blobToken = payload.value;
  },
  setLoading(state: any, payload: { type: string; value: boolean }) {
    state.assets[payload.type].loading = payload.value;
  },
  setCurrentPage(state: any, payload: number) {
    state.assets.videos.currentPage = payload;
  },
  clearCache(state: any) {
    const types = ["videos", "uploads", "slides"];
    _.forEach(types, (_type) => {
      state.assets[_type].total = 0;
      state.assets[_type].list = [];
      state.assets[_type].fullyLoaded = false;
      state.assets[_type].blobToken = null;
    });
  },
  setDefaultImage(state: any, payload: { type: string; image: GalleryAsset }) {
    const { type, image } = payload;
    if (type == "videos") {
      state.assets.defaults.video = image;
    } else {
      state.assets.defaults.image = image;
    }
  },
};
