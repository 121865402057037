import _ from "lodash";
import axios from "axios";
import { inject } from "vue";
import { useSlideUtils } from "./slide";
import { Module, ModuleMediaTypes } from "@/models/module";
import { ModuleSpeech, ModuleSpeechPutPost } from "@/models/moduleSpeech";
import { useAsset } from "./asset";
import { useStore } from "vuex";
import { ProjectAvatarVoiceTimeIndex } from "@/models/project";

export const useModuleSpeech = () => {
  const endpoints = inject("endpoints") as EndpointsEnum;
  const slideUtilsComposable = useSlideUtils();
  const assetComposable = useAsset();
  const store = useStore();

  const { id } = store.state.Auth.originalInfo;

  async function get(moduleData: Module) {
    let moduleSpeech: ModuleSpeech;
    if (moduleData.speech) {
      moduleSpeech = moduleData.speech;
    } else {
      moduleSpeech = await createUpdate(createObject(moduleData));
    }
    if (moduleSpeech) {
      if (moduleSpeech.mediaTrigger) {
        const assets: Array<{
          url: string;
          type: ModuleMediaTypes;
          id: string;
        }> = _.map(moduleSpeech.mediaTrigger.media, (media) => {
          return {
            url: media.mediaURL,
            type: media.triggerType,
            id: media.mediaId,
          };
        });
        await assetComposable.getModuleAssets(assets);
      }

      const utterance = {
        animationTrigger: moduleSpeech.animationTrigger,
        mediaTrigger: moduleSpeech.mediaTrigger,
        mediaAttached: moduleSpeech.mediaAttached,
        ssml: moduleSpeech.ssml,
      };

      moduleSpeech._layers = slideUtilsComposable.createModuleLayersObject(
        moduleSpeech.line_chr,
        utterance
      );
    }
    return moduleSpeech;
  }

  function createObject(
    moduleData: Module,
    moduleSpeech?: ModuleSpeech,
    timeIndexes?: Array<ProjectAvatarVoiceTimeIndex>
  ) {
    let speechModule: ModuleSpeechPutPost = {
      moduleId: moduleData.moduleId,
      slideData_chr: "",
      speechLength: 0,
      lastUpdatedByUserId_chr: id,
      animationTrigger: slideUtilsComposable.createAnimationTriggerObject(),
      mediaTrigger: slideUtilsComposable.createMediaTriggerObject(),
      pauseList: slideUtilsComposable.createPauseListObject(),
      pronunciation: [],
      utterances: [],
      SSML_Tag_Pronunciation: [],
      fullScrMedia: false,
      slideMediaType: null,
      slideMediaURL: "",
      htmlContent: "",
      htmlContent_Pause: "",
      wordIndexes: [],
      PhonemeType_Pronunciation: [],
      showCme: true,
    };

    if (moduleSpeech) {
      speechModule = {
        moduleId: moduleData.moduleId,
        slideData_chr: moduleSpeech.line_chr,
        speechLength: moduleSpeech.speechTime_lng,
        lastUpdatedByUserId_chr: id,
        animationTrigger: slideUtilsComposable.createAnimationTriggerObject(
          moduleSpeech.line_chr,
          moduleSpeech._layers
        ),
        mediaTrigger: slideUtilsComposable.createMediaTriggerObject(
          moduleSpeech.line_chr,
          moduleSpeech._layers
        ),
        pauseList: slideUtilsComposable.createPauseListObject(
          moduleSpeech._layers
        ),
        fullScrMedia: false,
        slideMediaType: null,
        slideMediaURL: "",
        htmlContent: moduleSpeech.ssml.htmlContent,
        htmlContent_Pause: moduleSpeech.ssml.htmlContent_Pauses,
        wordIndexes: timeIndexes ? _.map(timeIndexes, (v) => v.value) : [],
        showCme: moduleSpeech.showCme,
        ...slideUtilsComposable.createPronunciationObject(moduleSpeech._layers),
      };
    }

    return speechModule;
  }

  function createUpdate(speechInfo: ModuleSpeechPutPost) {
    speechInfo.lastUpdatedByUserId_chr = id;
    return axios
      .put(endpoints.SPEECH.UPDATE, speechInfo)
      .then((response: any) => {
        return response.speech as ModuleSpeech;
      });
  }

  return {
    createUpdate,
    createObject,
    get,
  };
};
