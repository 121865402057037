import _ from "lodash";
import axios from "axios";
import { computed, inject } from "vue";
import { useUtils } from "./utils";
import {
  BlobItem,
  GalleryAsset,
  SlideGalleryAsset,
  VideoStreamingItem,
} from "@/models/asset";
import { useStore } from "vuex";
import { ModuleMediaTypes } from "@/models/module";
import { v4 as uuidv4 } from "uuid";
import { useNotification } from "./notification";
import {
  BlockBlobClient,
  AnonymousCredential,
  newPipeline,
} from "@azure/storage-blob";

export const useAsset = () => {
  const endpoints = inject("endpoints") as EndpointsEnum;

  const store = useStore();
  const utilsComposable = useUtils();
  const notificationComposable = useNotification();

  const { clientId, clientName } = store.state.Auth.originalInfo;
  const itemsPerPage = 100;

  const containerName = computed(() => {
    if (!clientName) return "";
    return `${clientName
      .replace(/[^a-zA-Z0-9]/g, "")
      .toLowerCase()}(${clientId})`;
  });

  async function saveClientAsset(file: File) {
    const formData = utilsComposable.makeFormData({
      selectFile: file,
      fileType: file.type,
    });
    return axios
      .post(endpoints.ASSETS.UPLOAD_CLIENT, formData)
      .then((response) => response);
  }

  function uploadFile(file: File, showNotification = false) {
    const formatData = utilsComposable.makeFormData({
      imageFile: file,
      containerName: containerName.value,
    });

    return axios
      .post(endpoints.UPLOAD.UPLOAD_FILE, formatData)
      .then((response: any) => {
        const fileGallery = createGalleryAssetObject(response["path"][0]);
        store.commit("Asset/cacheAsset", {
          items: [fileGallery],
          type: "uploads",
        });
        if (showNotification) {
          notificationComposable.success("notification.asset_uploaded");
        }
        return fileGallery;
      });
  }

  function uploadFileByFolder(file: File, folderName: string) {
    const formatData = utilsComposable.makeFormData({
      imageFile: file,
      containerName: containerName.value,
      folderName,
    });

    return axios
      .post(endpoints.UPLOAD.UPLOAD_FILE_BY_FOLDER, formatData)
      .then((response: any) => {
        const fileGallery = createGalleryAssetObject(response["path"][0]);
        store.commit("Asset/cacheAsset", {
          items: [fileGallery],
          type: "uploads",
        });
        return fileGallery;
      });
  }

  async function uploadVideoBlob(
    file: File,
    url: string,
    onProgress: (progress: number) => void
  ): Promise<boolean> {
    const fileSize = file.size;
    try {
      const pipeline = newPipeline(new AnonymousCredential());
      const blockBlobClient = new BlockBlobClient(url, pipeline);
      const res = await blockBlobClient.uploadData(file, {
        blockSize: 4 * 1024 * 1024, // 4MB block size
        concurrency: 20, // 20 concurrency
        onProgress: (ev: any) => {
          const progress = Math.round((ev.loadedBytes / fileSize) * 100);
          onProgress(progress);
        },
        blobHTTPHeaders: {
          blobContentType: "video/quicktime",
        },
      });

      if (res._response.status >= 200 && res._response.status < 300) {
        return true;
      } else {
        throw "Failed to upload blob";
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  function createVideoAsset(fileName: string) {
    return axios
      .post(
        endpoints.MEDIA.CREATE_ASSET_VIDEO,
        {
          fileName: fileName,
        },
        {
          params: {
            clientId,
          },
        }
      )
      .then((response: any) => {
        const videoAsset = createGalleryAssetObject(response.media);
        store.commit("Asset/cacheAsset", {
          items: [videoAsset],
          type: "videos",
        });
        return response;
      });
  }

  function uploadImageBase64(file: File) {
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (!reader.result) return;
        const base64String = reader.result
          .toString()
          .replace(/^data:(.*,)?/, "");
        const formatData = utilsComposable.makeFormData({
          base64String,
          folderName: containerName.value,
          contentType: file.type,
        });

        return axios
          .post(endpoints.UPLOAD.UPLOAD_IMAGE_64, formatData)
          .then((response: any) => {
            resolve(response["path"][0] as BlobItem);
            return response["path"][0] as BlobItem;
          });
      };
    });
  }

  function uploadVimeo(file: any, showNotification = false) {
    const formatData = utilsComposable.makeFormData({
      videoFile: file,
      tagName: clientId,
    });

    return axios
      .post(endpoints.UPLOAD.UPLOAD_VIMEO, formatData)
      .then((response: any) => {
        const vimeoGallery = createGalleryAssetObject(response["video"]);
        store.commit("Asset/cacheAsset", {
          items: [vimeoGallery],
          type: "videos",
        });
        if (showNotification) {
          notificationComposable.success("notification.asset_uploaded");
        }
        return vimeoGallery;
      })
      .catch(() => {
        notificationComposable.error("media_gallery.fail_to_upload");
      });
  }
  function uploadAnimation(file: any) {
    const formatData = utilsComposable.makeFormData({
      videoFile: file,
      tagName: clientId,
    });

    return axios
      .post(endpoints.UPLOAD.UPLOAD_ANIMATIONS, formatData)
      .then((response: any) => {
        const vimeoGallery = createGalleryAssetObject(response["video"]);
        store.commit("Asset/cacheAsset", {
          items: [vimeoGallery],
          type: "videos",
        });
        return vimeoGallery;
      })
      .catch(() => {
        notificationComposable.error("media_gallery.fail_to_upload");
      });
  }

  function getBlobs(isSlide?: boolean) {
    const type = isSlide ? "slides" : "uploads";
    const { blobToken, fullyLoaded } = store.state.Asset.assets[type];

    if (fullyLoaded) return;

    store.commit("Asset/setLoading", {
      type,
      value: true,
    });

    return axios
      .get(endpoints.UPLOAD.LIST_BLOBS, {
        params: {
          containerName: containerName.value,
          pageSize: itemsPerPage,
          folderName: isSlide ? "slideimagegallery" : "imagegallery",
          blobContToken: blobToken,
        },
      })
      .then((response: any) => {
        store.commit("Asset/setBlobContToken", {
          type,
          value: response.blobContToken || null,
        });
        store.commit("Asset/setFullyLoaded", {
          type,
          value: !!response.blobContToken == false,
        });

        const items: Array<GalleryAsset> = [];

        _.forEach(response.blobs, (blob) => {
          items.push(createGalleryAssetObject(blob));
        });

        store.commit("Asset/cacheAsset", {
          items,
          type: isSlide ? "slides" : "uploads",
        });

        return items;
      })
      .finally(() => {
        store.commit("Asset/setLoading", {
          type,
          value: false,
        });
      });
  }
  function getSlideBlobs(isSlide?: boolean) {
    const { blobToken, fullyLoaded } = store.state.Asset.assets["slides"];

    if (fullyLoaded) return;

    store.commit("Asset/setLoading", {
      type: "slide",
      value: true,
    });

    return axios
      .get(endpoints.UPLOAD.LIST_SLIDE_BLOBS, {
        params: {
          containerName: containerName.value,
          pageSize: itemsPerPage,
          folderName: isSlide ? "slideimagegallery" : "imagegallery",
          blobContToken: blobToken,
        },
      })
      .then((response: any) => {
        store.commit("Asset/setBlobContToken", {
          type: "slide",
          value: response.blobContToken || null,
        });
        store.commit("Asset/setFullyLoaded", {
          type: "slide",
          value: !!response.blobContToken == false,
        });

        const items: Array<SlideGalleryAsset> = [];

        store.commit("Asset/cacheAsset", {
          items,
          type: isSlide ? "slides" : "uploads",
        });

        return items;
      })
      .finally(() => {
        store.commit("Asset/setLoading", {
          type: "slide",
          value: false,
        });
      });
  }

  function getVideos() {
    const videosInfo = store.state.Asset.assets.videos;

    const currentPage = videosInfo.currentPage ? videosInfo.currentPage : 0;

    store.commit("Asset/setLoading", {
      type: "videos",
      value: true,
    });

    return axios
      .get(endpoints.MEDIA.GET_MEDIA, {
        params: {
          clientId: clientId,
          pageNumber: currentPage,
          pageSize: itemsPerPage,
          type: "Video",
        },
      })
      .then((response: any) => {
        const cacheds: Array<GalleryAsset> = [];

        _.forEach(response.media, (blob) => {
          cacheds.push(createGalleryAssetObject(blob));
        });
        store.commit("Asset/cacheAsset", {
          items: cacheds,
          total: response.totalCount,
          pages: response.totalPages,
          type: "videos",
        });

        store.commit("Asset/setFullyLoaded", {
          type: "videos",
          value: videosInfo.list.length >= response.totalCount,
        });

        store.commit("Asset/setCurrentPage", currentPage + 1);

        return cacheds;
      })
      .finally(() => {
        store.commit("Asset/setLoading", {
          type: "videos",
          value: false,
        });
      });
  }

  function getFileByFolder(folderName: string) {
    return axios
      .get(endpoints.UPLOAD.GET_FILE_BY_FOLDER, {
        params: {
          folderName,
          containerName: containerName.value,
        },
      })
      .then((response: any) => {
        return response.images as Array<BlobItem>;
      });
  }
  function deleteFile(blobName: string) {
    return axios
      .delete(endpoints.UPLOAD.DELETE_FILE, {
        params: {
          blobName,
        },
      })
      .then((response: any) => {
        notificationComposable.success("notification.file_deleted");
        return response;
      });
  }
  function deleteVideo(videoId: string) {
    return axios
      .delete(endpoints.UPLOAD.DELETE_VIDEO, {
        params: {
          videoId,
        },
      })
      .then((response: any) => {
        notificationComposable.success("notification.file_deleted");
        return response;
      });
  }

  function uploadBackground(file: File) {
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (!reader.result) return;
        const base64String = reader.result
          .toString()
          .replace(/^data:(.*,)?/, "");
        const formData = utilsComposable.makeFormData({
          base64String,
          fileName: file.name,
          folderName: containerName.value,
          contentType: file.type,
        });

        return axios
          .post(endpoints.UPLOAD.UPLOAD_BACKGROUND, formData)
          .then((response: any) => {
            resolve(response["path"][0] as BlobItem);
            return response["path"][0] as BlobItem;
          });
      };
    });
  }

  function uploadQRCode(file: File): Promise<BlobItem> {
    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (!reader.result) return;
        const base64String = reader.result
          .toString()
          .replace(/^data:(.*,)?/, "");
        const formData = utilsComposable.makeFormData({
          base64String,
          fileName: file.name,
          folderName: containerName.value,
          contentType: file.type,
        });

        return axios
          .post(endpoints.UPLOAD.UPLOAD_QRCODE, formData)
          .then((response: any) => {
            resolve(response["path"][0] as BlobItem);
            return response["path"][0] as BlobItem;
          });
      };
    });
  }

  async function getAll() {
    await getBlobs();
    await getBlobs(true);
    await getVideos();
  }

  function createGalleryAssetObject(file: VideoStreamingItem & BlobItem) {
    const isVideo = file.type == 1;

    let url = "";
    if (isVideo) {
      url = file.url;
    } else {
      const origin = new URL(file.uri).origin;
      url = `${origin}/${file.blobContainerName}/${file.name}`;
    }

    const galleryAsset: GalleryAsset = {
      id: isVideo ? file.id : uuidv4(),
      name: isVideo ? file.fileName : file.name,
      url,
      thumb: isVideo ? file.thumbnailUrl : file.uri,
      type: isVideo ? "video" : "image",
      duration:
        isVideo && file.duration
          ? timeStringToSeconds(file.duration.toString()) * 1000
          : null,
      mediaStreamingUrl: isVideo ? file.streamingUrl : undefined,
      status: isVideo ? file.status : null,
    };
    return galleryAsset;
  }

  function getCachedFile(url: string): GalleryAsset | null {
    if (!url) return null;
    const cacheFiles = store.state.Asset.assets;
    const assets: Array<GalleryAsset> = [
      ...cacheFiles.videos.list,
      ...cacheFiles.uploads.list,
      ...cacheFiles.slides.list,
    ];
    const file = _.find(
      assets,
      (asset) =>
        asset.url == url ||
        asset.alternativeUrl == url ||
        asset.id == url ||
        asset.url.includes(url) ||
        (!!asset.alternativeUrl && asset.alternativeUrl.includes(url))
    );
    return file ? file : null;
  }

  function getAssetTag(clipId: string, disableCaching = false) {
    return axios
      .get(endpoints.MEDIA.GET_MEDIA_BY_ID.replace("{mediaId}", clipId), {
        params: { clientId: clientId },
      })
      .then((file: any) => {
        if (!file || !file.media) return;
        const galleryAsset = createGalleryAssetObject(file.media);
        //galleryAsset.alternativeUrl = file.media.url;
        if (disableCaching) return galleryAsset;
        store.commit("Asset/cacheAsset", {
          items: [galleryAsset],
          type: "videos",
        });
        return galleryAsset;
      });
  }

  function movePresentationSlides(blobNum: number) {
    return axios
      .get(endpoints.UPLOAD.MOVE_PRESENTATION, {
        params: { containerName: containerName.value, blobNum },
      })
      .then((response: any) => {
        response as GalleryAsset;
      });
  }

  async function getModuleAssets(
    list: Array<{ url: string; type: ModuleMediaTypes; id: string }>
  ) {
    const loop = async (i: number) => {
      const item = list[i];

      if (!item) return;

      if (!getCachedFile(item.url)) {
        if (item.type === "Photo" || item.type === "image") {
          const assetInfoName = decodeAssetURL(item.url);

          const obj = {
            id: uuidv4(),
            name: assetInfoName,
            url: item.url,
            thumb: item.url,
            type: "image",
            duration: null,
          };

          store.commit("Asset/cacheAsset", {
            items: [obj],
            type: obj.url.includes("slideimagegallery") ? "slides" : "uploads",
          });
        } else {
          // const isExternal = item.url.includes("external");
          // const isPlayback = item.url.includes("playback");
          // const splitUrl = item.url.split(
          //   isPlayback ? "playback" : isExternal ? "external" : "video"
          // )[1];

          await getAssetTag(item.id);
        }
      }

      await loop(i + 1);
    };

    await loop(0);
  }

  function decodeAssetURL(url: string) {
    const pathname = new URL(url).pathname;
    const urlSplited = pathname.replace("/%2F/g ", "/").split("/");
    return _.filter(urlSplited, (item, index) => index > 1).join("/");
  }

  function timeStringToSeconds(timeString: string) {
    const parts = timeString.split(":");
    let seconds = 0;

    if (parts.length === 3) {
      seconds += parseInt(parts[0], 10) * 3600;
      seconds += parseInt(parts[1], 10) * 60;
      seconds += parseFloat(parts[2]);
    } else if (parts.length === 2) {
      seconds += parseInt(parts[0], 10) * 60;
      seconds += parseFloat(parts[1]);
    }

    return seconds;
  }

  return {
    saveClientAsset,
    getBlobs,
    getVideos,
    uploadFile,
    uploadVimeo,
    getFileByFolder,
    uploadBackground,
    uploadQRCode,
    getAll,
    createGalleryAssetObject,
    getCachedFile,
    getAssetTag,
    getModuleAssets,
    getSlideBlobs,
    movePresentationSlides,
    uploadFileByFolder,
    uploadImageBase64,
    deleteFile,
    uploadAnimation,
    deleteVideo,
    containerName,
    itemsPerPage,
    createVideoAsset,
    uploadVideoBlob,
  };
};
