import _ from "lodash";
import axios from "axios";
import { inject } from "vue";
import { useStore } from "vuex";
import {
  ModuleIntakePutPost,
  ModuleIntake,
  ModuleIntakeQuestion,
  ModuleIntakeQuestionDiagram,
  ModuleIntakeQuestionBinary,
  ModuleIntakeQuestionMCQ,
  ModuleIntakeQuestionRange,
  ModuleIntakeQuestionImage,
  ModuleIntakeEntryPustPost,
} from "@/models/moduleIntake";
import { Module, ModuleMediaTypes } from "@/models/module";
import { ProjectAvatarVoiceTimeIndex } from "@/models/project";
import { useAsset } from "./asset";
import { useSlideUtils } from "./slide";
import { useNotification } from "./notification";

export const useModuleIntake = () => {
  const dayjs = inject("dayjs") as DayjsEnum;
  const endpoints = inject("endpoints") as EndpointsEnum;

  const store = useStore();
  const assetComposable = useAsset();
  const slideUtilsComposable = useSlideUtils();
  const notificationComposable = useNotification();

  const { email, id } = store.state.Auth.originalInfo;

  async function get(moduleData: Module) {
    let moduleIntake: ModuleIntake;

    if (moduleData.intake) {
      moduleIntake = await axios.get(endpoints.INTAKE.GET, {
        params: {
          id: moduleData.intake.id,
          intakeId: moduleData.intake.intakeId_chr,
        },
      });
    } else {
      moduleIntake = await createUpdate(createObject(moduleData));
    }

    const assets: Array<{ url: string; type: ModuleMediaTypes; id: string }> =
      [];

    _.forEach(moduleIntake.questions, (question) => {
      assets.concat(
        _.map(question.mediaTrigger.media, (media) => {
          return {
            url: media.mediaURL,
            type: media.triggerType,
            id: media.mediaId,
          };
        })
      );
    });

    await assetComposable.getModuleAssets(assets);

    _.forEach(moduleIntake.questions, (question) => {
      question._layers = slideUtilsComposable.createModuleLayersObject(
        question.question_chr || "",
        question
      );
    });

    return moduleIntake;
  }

  function createUpdate(payload: ModuleIntakePutPost) {
    payload.lastUpdatedByUserId_chr = email;
    return axios
      .post(endpoints.INTAKE.CREATE_UPDATE, payload)
      .then((response: any) => {
        return response as ModuleIntake;
      });
  }

  function createUpdateEntry(payload: ModuleIntakeEntryPustPost) {
    payload.lastUpdatedByUserId_chr = email;
    return axios
      .post(endpoints.INTAKE.CREATE_UPDATE_ENTRY, payload)
      .then((response: any) => {
        return response as ModuleIntake;
      });
  }

  function createEntryObject(
    moduleData: Module,
    intake: ModuleIntake,
    question: ModuleIntakeQuestion,
    timeIndexes?: Array<ProjectAvatarVoiceTimeIndex>
  ) {
    const entry: ModuleIntakeEntryPustPost = {
      lastUpdatedDateTime_dtm: dayjs.utc().format(),
      lastUpdatedByUserId_chr: email,
      moduleId_chr: moduleData.moduleId,
      speechTime_lng: 0,
      question: {
        ...question,
        ...slideUtilsComposable.createBaseObject(
          question.question_chr || "",
          question._layers,
          timeIndexes ? _.map(timeIndexes, (v) => v.value) : []
        ),
      },
    };

    return entry;
  }

  function createObject(moduleData: Module, intake?: ModuleIntake) {
    const obj: ModuleIntakePutPost = {
      fullScrMedia: intake ? intake.fullScrMedia : false,
      isShowSummary: intake ? intake.isShowSummary : false,
      isReadTitles: intake ? intake.isReadTitles : true,
      readQuestion: intake ? intake.readQuestion : false,
      lastUpdatedDateTime_dtm: dayjs.utc().format(),
      lastUpdatedByUserId_chr: email,
      moduleId_chr: moduleData.moduleId,
    };

    return obj;
  }

  function createQuestionResponseObject() {
    const questionBase: ModuleIntakeQuestion = {
      id: null,
      question_chr: "",
      isQuestionRequired: false,
      questionType: "Text_Entry",
      title: "New Question",
      binaryQ: null,
      rangeQ: null,
      imageQ: null,
      mcqQ: null,
      diagramQ: null,
      ...slideUtilsComposable.createBaseResponseObject(),
    };
    return questionBase;
  }

  function createQuestionMcqObject() {
    const questionMcq: ModuleIntakeQuestionMCQ = {
      options: ["Option 1", "Option 2"],
      allowMultipleSelection: false,
    };

    return questionMcq;
  }

  function createQuestionDiagramObject() {
    const questionDiagram: ModuleIntakeQuestionDiagram = {
      imageURL: null,
      markerType: null,
      markerColor: null,
    };
    return questionDiagram;
  }

  function createQuestionBinaryObject() {
    const questionBinary: ModuleIntakeQuestionBinary = {
      option1: null,
      option2: null,
    };
    return questionBinary;
  }

  function createQuestionRangeObject() {
    const questionRange: ModuleIntakeQuestionRange = {
      min: 0,
      max: 10,
      isNaturalNumbers: true,
    };
    return questionRange;
  }

  function createQuestionImageObject() {
    const questionImage: ModuleIntakeQuestionImage = {
      imageURL: null,
      checkmarkURL: null,
    };
    return questionImage;
  }

  function deleteEntry(intakeId: string, entryId: string, moduleId: string) {
    return axios.delete(endpoints.INTAKE.DELETE_ENTRY, {
      data: { intakeId, entryId, lastUpdatedByUserId: id, moduleId },
    });
  }

  function sortQuestions(moduleData: Module, intake: ModuleIntake) {
    return axios.put(endpoints.INTAKE.SORT, {
      intakeId: intake.intakeId_chr,
      entryIds: _.map(intake.questions, (question) => question.id),
      moduleId: moduleData.moduleId,
      lastUpdatedByUserId_chr: email,
    });
  }

  function revertEntryToPublished(
    intakeId: string,
    slideId: string,
    moduleId: string
  ) {
    return axios
      .post(endpoints.INTAKE.REVERT_ENTRY, null, {
        params: { intakeId, slideId, moduleId },
      })
      .then((response: any) => {
        notificationComposable.success("form.revert_entry_success");
        return response as Module;
      })
      .catch((error) => {
        notificationComposable.error("form.revert_entry_error");
        throw error;
      });
  }

  return {
    get,
    createUpdate,
    createUpdateEntry,
    deleteEntry,
    createObject,
    createQuestionResponseObject,
    createQuestionMcqObject,
    createQuestionDiagramObject,
    createQuestionBinaryObject,
    createQuestionRangeObject,
    createQuestionImageObject,
    createEntryObject,
    sortQuestions,
    revertEntryToPublished,
  };
};
