import _ from "lodash";
import axios from "axios";
import { inject } from "vue";
import { useStore } from "vuex";
import { useSlideUtils } from "./slide";
import { useNotification } from "./notification";
import {
  ModuleQuiz,
  ModuleQuizSlidePutPost,
  ModuleQuizSlideQuestionPutPost,
  ModuleQuizSlide,
  ModuleQuizSlideAnswerPutPost,
  ModuleQuizSlideAnswer,
} from "@/models/moduleQuiz";
import {
  Module,
  ModuleMediaTypes,
  ModuleTextToSpeechLayerItem,
} from "@/models/module";
import { useAsset } from "./asset";
import { ProjectAvatarVoiceTimeIndex } from "@/models/project";

export const useModuleQuiz = () => {
  const dayjs = inject("dayjs") as DayjsEnum;
  const endpoints = inject("endpoints") as EndpointsEnum;

  const store = useStore();
  const slideUtilsComposable = useSlideUtils();
  const notificationComposable = useNotification();
  const assetComposable = useAsset();

  const { email, id } = store.state.Auth.originalInfo;

  async function get(moduleData: Module) {
    let moduleQuiz: ModuleQuiz;

    if (moduleData.quiz) {
      moduleQuiz = await axios.get(endpoints.QUIZ.GET, {
        params: {
          id: moduleData.quiz.id,
          quizId: moduleData.quiz.quizId_chr,
        },
      });
    } else {
      moduleQuiz = await createUpdateSlide(createSlideObject(moduleData));
    }

    const assets: Array<{ url: string; type: ModuleMediaTypes; id: string }> =
      [];

    _.forEach(moduleQuiz.slides, (slide) => {
      _.forEach(slide.answers, (answer) => {
        if (answer.mediaTrigger) {
          assets.concat(
            _.map(answer.mediaTrigger.media, (media) => {
              return {
                url: media.mediaURL,
                type: media.triggerType,
                id: media.mediaId,
              };
            })
          );
        }
      });
    });

    await assetComposable.getModuleAssets(assets);

    _.forEach(moduleQuiz.slides, (slide) => {
      slide.question._layers = slideUtilsComposable.createModuleLayersObject(
        slide.question.question_chr,
        slide.question
      );

      if (slide.question.intro) {
        slide.question.intro._layers =
          slideUtilsComposable.createModuleLayersObject(
            slide.question.intro.line_chr,
            slide.question.intro
          );
      } else {
        slide.question.intro = slideUtilsComposable.createIntroResponseObject();
      }

      _.forEach(slide.answers, (answer) => {
        answer._layers = slideUtilsComposable.createModuleLayersObject(
          answer.answer_chr,
          answer
        );
        if (answer.outro) {
          answer.outro._layers = slideUtilsComposable.createModuleLayersObject(
            answer.outro.outroLine_chr,
            answer.outro
          );
        } else {
          answer.outro = slideUtilsComposable.createOutroResponseObject();
        }

        if (answer.mediaTrigger) {
          assets.concat(
            _.map(answer.mediaTrigger.media, (media) => {
              return {
                url: media.mediaURL,
                type: media.triggerType,
                id: media.mediaId,
              };
            })
          );
        }
      });
    });

    return moduleQuiz;
  }

  function createUpdateSlide(quizInfo: ModuleQuizSlidePutPost) {
    quizInfo.lastUpdatedByUserId_chr = email;
    if (!quizInfo.slideId_chr) delete quizInfo.slideId_chr;
    return axios.post(endpoints.QUIZ.CREATE, quizInfo).then((response: any) => {
      return response as ModuleQuiz;
    });
  }

  function deleteSlide(quizId: string, slideId: string, moduleId: string) {
    return axios
      .delete(endpoints.QUIZ.DELETE, {
        data: {
          quizId,
          slideId,
          lastUpdatedByUserId: id,
          moduleId,
        },
      })
      .then((response) => {
        notificationComposable.success("quiz.delete_quiz_success");
        return response;
      })
      .catch((error) => {
        notificationComposable.error("quiz.delete_quiz_success");
        return error;
      });
  }

  function createAnswerResponseObject() {
    return {
      isCorrect: false,
      answer_chr: "",
      presentationModule: null,
      outro: slideUtilsComposable.createOutroResponseObject(),
      ...slideUtilsComposable.createBaseResponseObject(),
    } as ModuleQuizSlideAnswer;
  }

  function createQuestionObject(
    value: string,
    introValue: string,
    allowMultiple: boolean,
    layers?: Array<ModuleTextToSpeechLayerItem>,
    introLayers?: Array<ModuleTextToSpeechLayerItem>,
    timeIndexes?: Array<ProjectAvatarVoiceTimeIndex>,
    introTimeIndexes?: Array<ProjectAvatarVoiceTimeIndex>
  ) {
    const questionObj: ModuleQuizSlideQuestionPutPost = {
      question_chr: value,
      ssml: slideUtilsComposable.createSSMLObject(
        value,
        layers,
        timeIndexes ? _.map(timeIndexes, (v) => v.value) : []
      ),
      mediaTrigger: slideUtilsComposable.createMediaTriggerObject(
        value,
        layers
      ),
      animationTrigger: slideUtilsComposable.createAnimationTriggerObject(
        value,
        layers
      ),
      questionIntro: slideUtilsComposable.createIntroObject(
        introValue ? introValue : value,
        introLayers,
        introTimeIndexes ? _.map(introTimeIndexes, (v) => v.value) : []
      ),
      allowMultiple,
    };
    return questionObj;
  }

  function createAnswerObject(
    value: string,
    outroValue: string,
    correct: boolean,
    layers?: Array<ModuleTextToSpeechLayerItem>,
    outroLayers?: Array<ModuleTextToSpeechLayerItem>,
    timeIndexes?: Array<ProjectAvatarVoiceTimeIndex>,
    outroTimeIndexes?: Array<ProjectAvatarVoiceTimeIndex>
  ) {
    const answer: ModuleQuizSlideAnswerPutPost = {
      answerOutro: {
        outroLine_chr: outroValue,
        ssml: slideUtilsComposable.createSSMLObject(
          outroValue,
          outroLayers,
          outroTimeIndexes ? _.map(outroTimeIndexes, (v) => v.value) : []
        ),
        mediaTrigger: slideUtilsComposable.createMediaTriggerObject(
          outroValue,
          outroLayers
        ),
        animationTrigger: slideUtilsComposable.createAnimationTriggerObject(
          outroValue,
          outroLayers
        ),
      },
      answer_chr: value,
      isCorrect: correct,
      presentationModule: null,
      ssml: slideUtilsComposable.createSSMLObject(
        value,
        layers,
        timeIndexes ? _.map(timeIndexes, (v) => v.value) : []
      ),
      mediaTrigger: slideUtilsComposable.createMediaTriggerObject(
        value,
        layers
      ),
      animationTrigger: slideUtilsComposable.createAnimationTriggerObject(
        value,
        layers
      ),
    };

    return answer;
  }

  function createSlideObject(
    module: Module,
    quiz?: ModuleQuiz,
    slide?: ModuleQuizSlide,
    timeIndexes?: {
      question: {
        base: Array<ProjectAvatarVoiceTimeIndex>;
        intro: Array<ProjectAvatarVoiceTimeIndex>;
      };
      answers: Array<{
        base: Array<ProjectAvatarVoiceTimeIndex>;
        outro: Array<ProjectAvatarVoiceTimeIndex>;
      }>;
    }
  ) {
    let question: ModuleQuizSlideQuestionPutPost;
    const answers: Array<ModuleQuizSlideAnswerPutPost> = [];

    if (slide) {
      question = createQuestionObject(
        slide.question.question_chr,
        slide.question.intro.line_chr,
        slide.question.allowMultiple,
        slide.question._layers,
        slide.question.intro._layers,
        timeIndexes ? timeIndexes.question.base : [],
        timeIndexes ? timeIndexes.question.intro : []
      );

      _.forEach(slide.answers, (answer, index) => {
        answers.push(
          createAnswerObject(
            answer.answer_chr,
            answer.outro.outroLine_chr,
            answer.isCorrect,
            answer._layers,
            answer.outro._layers,
            timeIndexes ? timeIndexes.answers[index].base : [],
            timeIndexes ? timeIndexes.answers[index].outro : []
          )
        );
      });
    } else {
      question = createQuestionObject("Question", "", false);
      answers.push(
        createAnswerObject("Answer 1", "Answer Outro 1", true),
        createAnswerObject("Answer 2", "Answer Outro 2", false)
      );
    }

    const slideObject: ModuleQuizSlidePutPost = {
      fullScrMedia: false,
      listStyleId: quiz ? quiz.listStyleId : "",
      question: question,
      answers: answers,
      readAnswers: false,
      readQuestion: true,
      lastUpdatedByUserId_chr: email,
      moduleId: module.moduleId,
      slideId_chr: slide ? slide.id : "",
      lastUpdatedDateTime_dtm: dayjs.utc().format(),
      speechTime_lng: 0,
      showCme: slide ? slide.showCme : true,
    };

    return slideObject;
  }

  function sortSlides(moduleData: Module, quiz: ModuleQuiz) {
    return axios.put(endpoints.QUIZ.SORT, {
      quizId: quiz.quizId_chr,
      slideIds: _.map(quiz.slides, (slide) => slide.id),
      moduleId: moduleData.moduleId,
      lastUpdatedByUserId_chr: email,
    });
  }

  function revertSlideToPublished(
    quizId: string,
    slideId: string,
    moduleId: string
  ) {
    return axios
      .post(endpoints.QUIZ.REVERT_SLIDE, null, {
        params: { quizId, slideId, moduleId },
      })
      .then((response: any) => {
        notificationComposable.success("quiz.revert_slide_success");
        return response as Module;
      })
      .catch((error) => {
        notificationComposable.error("quiz.revert_slide_error");
        throw error;
      });
  }

  return {
    get,
    sortSlides,
    createUpdateSlide,
    deleteSlide,
    createSlideObject,
    createAnswerResponseObject,
    revertSlideToPublished,
  };
};
