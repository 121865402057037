import axios from "axios";
import { inject } from "vue";
import { CodeAnalytics } from "@/models/code";
import dayjs from "dayjs";
import { useNotification } from "./notification";
import { AnalyticsAnswers, AnalyticsUserPerDay } from "@/models/analytics";

export const useAnalytic = () => {
  const endpoints = inject("endpoints") as EndpointsEnum;
  const notificationComposable = useNotification();

  const _formatDate = (date: any, addDay?: boolean): string => {
    const dateObj = dayjs(date);
    if (!dateObj.isValid() && typeof date === "string") {
      date = date.replace(/-/g, "/");
    }
    return dayjs(date)
      .add(addDay ? 1 : 0, "day")
      .toISOString();
  };

  async function getUsersPerDay(
    codes: Array<string>,
    dates: Array<any>,
    subcodes?: Array<string>
  ) {
    return new Promise(
      (resolve: (value: AnalyticsUserPerDay) => void, reject: () => void) => {
        if (!codes.length) {
          const userPerDay: AnalyticsUserPerDay = {
            totalUsers: {},
            uniqueUsers: {},
          };
          resolve(userPerDay);
        } else {
          axios
            .post(endpoints.ANALYTICS.USERS_PER_DAY, {
              codeids: codes,
              start: _formatDate(dates[0]),
              end: _formatDate(dates[1], true),
              codesEntered: subcodes,
            })
            .catch(() => reject())
            .then((response: any) => {
              resolve(response as AnalyticsUserPerDay);
            });
        }
      }
    );
  }

  function getPopularStats(codes: Array<string>, dates: Array<any>) {
    return axios
      .post(endpoints.ANALYTICS.GET_POPULAR_STATS, {
        codeids: codes,
        start: _formatDate(dates[0]),
        end: _formatDate(dates[1], true),
      })
      .then((response: any) => {
        return response;
      });
  }

  function getTopModules(codes: Array<string>, dates: Array<any>) {
    return axios
      .post(endpoints.ANALYTICS.GET_TOP_MODULES, {
        codeids: codes,
        start: _formatDate(dates[0]),
        end: _formatDate(dates[1], true),
      })
      .then((response: any) => {
        return response;
      });
  }

  function getTopProjects(codes: Array<string>, dates: Array<any>) {
    return axios
      .post(endpoints.ANALYTICS.GET_TOP_PROJECTS, {
        codeids: codes,
        start: _formatDate(dates[0]),
        end: _formatDate(dates[1], true),
      })
      .then((response: any) => {
        return response;
      });
  }

  function getFeedback(codes: Array<string>, dates: Array<any>) {
    return axios
      .post(endpoints.ANALYTICS.GET_FEEDBACK, {
        codeids: codes,
        start: _formatDate(dates[0]),
        end: _formatDate(dates[1], true),
      })
      .then((response: any) => {
        return response;
      });
  }

  function getOSBreakdown(codes: Array<string>, dates: Array<any>) {
    return axios
      .post(endpoints.ANALYTICS.GET_OS_BREAKDOWN, {
        codeids: codes,
        start: _formatDate(dates[0]),
        end: _formatDate(dates[1], true),
      })
      .then((response: any) => {
        return response["breakdown"];
      });
  }

  function getQuestionInfo(
    dates: Array<any>,
    codeId: string,
    subcodes?: Array<string>,
    codeIds?: Array<string>
  ) {
    const payload: any = {
      start: _formatDate(dates[0]),
      end: _formatDate(dates[1], true),
      codesEntered: subcodes,
    };
    if (codeId !== "") payload["codeid"] = codeId;
    if (codeIds !== undefined) payload["codeids"] = codeIds;

    return axios
      .post(endpoints.ANALYTICS.GET_QUESTION_INFO, payload)
      .then((response: any) => {
        return response;
      });
  }

  function getPresentationInfo(
    dates: Array<any>,
    codeId: string,
    subcodes?: Array<string>,
    codeIds?: Array<string>
  ) {
    const payload: any = {
      start: _formatDate(dates[0]),
      end: _formatDate(dates[1], true),
      codesEntered: subcodes,
    };
    if (codeId !== "") payload["codeid"] = codeId;
    if (codeIds !== undefined) payload["codeids"] = codeIds;

    return axios
      .post(endpoints.ANALYTICS.GET_PRESENTATION_INFO, payload)
      .then((response: any) => {
        return response;
      });
  }

  function getQuizInfo(
    dates: Array<any>,
    codeId: string,
    subcodes?: Array<string>
  ) {
    return axios
      .post(endpoints.ANALYTICS.GET_QUIZ_INFO, {
        codeid: codeId,
        start: _formatDate(dates[0]),
        end: _formatDate(dates[1], true),
        quizPassThreshold: 70,
        codesEntered: subcodes,
      })
      .then((response: any) => {
        return response;
      });
  }

  function getCodeDetails(
    codeId: string,
    dates: Array<any>,
    subcodes?: Array<string>
  ) {
    return axios
      .post(endpoints.ANALYTICS.GET_CODE_DETAILS, {
        codeid: codeId,
        start: _formatDate(dates[0]),
        end: _formatDate(dates[1], true),
        codesEntered: subcodes,
      })
      .then((response: any) => {
        return response;
      });
  }

  function getTopCodes(codesIds: Array<string>, dates?: Array<any>) {
    const filter = {
      end: dayjs().format("DD-MMM-YYYY"),
      start: dayjs().subtract(7, "day").format("DD-MMM-YYYY"),
    };

    if (dates) {
      filter.end = _formatDate(dates[1], true);
      filter.start = _formatDate(dates[0]);
    }

    return axios
      .post(endpoints.CODES.TOP_LIST, {
        codeids: codesIds,
        ...filter,
      })
      .then((response: any) => {
        return response["views"] as Array<CodeAnalytics>;
      });
  }

  async function getCodes(
    codes: Array<string>,
    dates: Array<any>,
    type: string
  ) {
    if (type === "AIVIO") {
      return await getTopCodes(codes, dates);
    } else if (type === "Module") {
      const modules = await getTopModules(codes, dates);
      return modules["views"];
    } else {
      const projects = await getTopProjects(codes, dates);
      return projects["projectViews"];
    }
  }

  function getMultipleCodeDetails(codeIds: Array<string>, dates: Array<any>) {
    return axios
      .post(endpoints.ANALYTICS.GET_MULTIPLE_CODE_DETAILS, {
        codeids: codeIds,
        start: _formatDate(dates[0]),
        end: _formatDate(dates[1], true),
      })
      .then((response: any) => {
        return response.data.codeName as Array<string>;
      });
  }

  function getFormInfo(sessionId: string, codeId: string, entryId: string) {
    return axios
      .post(endpoints.ANALYTICS.GET_FORM_INFO, {
        sessionID: sessionId,
        codeID: codeId,
        entryID: entryId,
      })
      .then((response: any) => {
        return response.answers as Array<AnalyticsAnswers>;
      })
      .catch((error) => {
        notificationComposable.error("Cannot find session with given id");
        throw error;
      });
  }

  function getSessionInfo(sessionId: string, codeId: string) {
    return axios
      .post(endpoints.ANALYTICS.GET_SESSION_INFO, {
        sessionID: sessionId,
        codeID: codeId,
      })
      .then((response: any) => {
        return response;
      })
      .catch((error) => {
        notificationComposable.error("Cannot find session with given id");
        throw error;
      });
  }

  function getExportData(codeIds: Array<string>, dates: Array<any>) {
    return axios
      .post(endpoints.ANALYTICS.GET_ALL_DATA, {
        codeids: codeIds,
        start: _formatDate(dates[0]),
        end: _formatDate(dates[1], true),
      })
      .then((response: any) => {
        notificationComposable.success("Session's data exported successfully");
        return response;
      })
      .catch((error) => {
        notificationComposable.error("An error ocourred while exporting data");
        throw error;
      });
  }
  return {
    getUsersPerDay,
    getPopularStats,
    getTopModules,
    getTopProjects,
    getFeedback,
    getOSBreakdown,
    getQuestionInfo,
    getPresentationInfo,
    getQuizInfo,
    getCodeDetails,
    getCodes,
    getMultipleCodeDetails,
    getFormInfo,
    getSessionInfo,
    getExportData,
  };
};
